import { format } from "d3-format";
import * as R from "ramda";
import formatDateFn from "date-fns/fp/format";
import parseISO from "date-fns/fp/parseISO";

// values for datatypes
export const NUMBER = "Number";
export const PERCENT = "Percent";

export const DATA_TYPES = [NUMBER, PERCENT];

export const formatNumber = format(",");

export const formatPercentage = format(".2%");

export const formatPercentageNice = (percentage) => {
  // show 1 decimal point if there is < 1% of, eg, 0.3%
  if (Math.abs(percentage) < 0.01) {
    return format(".1%")(percentage);
  }
  // otherwise, just show whole numebers, eg, 33%
  else {
    return format(".0%")(percentage);
  }
};

/**
 * formatDate
 * @param  {String} isoDate contentful dates are YYYY-MM-DD, compatible as ISO date
 * @return {String}
 */
export const formatDate = (isoDate) => {
  return R.unless(
    R.isNil,
    R.compose(formatDateFn("MMM do, yyyy"), parseISO),
  )(isoDate);
};

/**
 * formatPhone
 * http://mikelambert.me/functional-fun/#eJy9Uztv2zAQ3vUrDkKGIypbluPUg5FOWboUhhCgg6MgtE1bQiVRJSmjgeH/3hP1MN06QJOhWsh7fI+jyKyopDIQw07JAnzFiy33F563kaU2kItyb9KvGu67Ldx/8QDi8UYSTgukoAnFz5rnGtseFnTZNqSAEaH4ZYVa3p1UBTfLVJaCqLFqNoy44UjdSphalX+LEHSLKxsA9CsMHnHeCffdDrj9sOLKaKvzcnO0wSpKTqN+P01OL4HTH4/J5CbF8BmftsfbE2uW2YndhI7SsE2Ct61Fk/d4Q8ccA8fd4PT2X5x+wHA85nm+5Frj6uw9YgEVtGmUv2cmRT/yWULJ/zNR9KGR4vFjAGjV7e1KAghD2NF4a775AUaCSQVkZVUbOPC8FhaYDHdXiSrnG4Hh00O4D8D3mSVQopAHAeIg1KtJs3IPayIo62ItlO6gW7HjdW4eJTao5vp3F3zhnegh9G/LCD28ADrAWqlXRDrkvTBBa8y6vzhbWZvGMKXpHwll+ofX5gNo4VbUeWIew5aPLVzxaPKQ7TNiO1tBHz9Ppgxmd9Focjen/7xwAPMr/dc7v8klV7qZrD1hMnzG2FQ/IjlyvKBP8iOX811FeLMYjf7EeueJLqdw80gFdrVChQumbmL0P82mcM3n0GCRNv0b3P6EXA==
 */
const lengthIs = (length) => R.compose(R.equals(length), R.length);

export const formatPhone = (phone) => {
  return R.compose(
    R.cond([
      [
        lengthIs(7),
        R.compose(
          (parts) => `${parts[1]}-${parts[2]}`,
          R.match(/^(\d{3})(\d{4})$/),
        ),
      ],
      [
        lengthIs(10),
        R.compose(
          (parts) => `(${parts[1]}) ${parts[2]}-${parts[3]}`,
          R.match(/^(\d{3})(\d{3})(\d{4})$/),
        ),
      ],
      [
        R.allPass([lengthIs(11), R.startsWith("1")]),
        R.compose(
          (parts) => `(${parts[1]}) ${parts[2]}-${parts[3]}`,
          R.match(/^1(\d{3})(\d{3})(\d{4})$/),
        ),
      ],
      [R.T, () => phone], // fallback to the input value
    ]),
    R.replace(/\D/g, ""), // remove everything but numbers
    R.defaultTo(""),
  )(phone);
};

// https://github.com/d3/d3-format#locale_formatPrefix
// convert from SI prefix abbreviations to standard
const siToIllions = R.compose(
  R.replace("k", "k"), // thousands
  R.replace("M", "m"), // millions
  R.replace("G", "b"), // billions
  R.replace("T", "t"), // trillions
);

export function dataTypeFormatter(dataType) {
  const defaultNonNumbers = (fn) => R.ifElse(Number.isFinite, fn, () => "-");

  // format(",~s")(0.5) is returning 500m instead of 0.5 🤔
  // we need to hack around that...
  const isZeroToOne = (d) => d > 0 && d < 1;

  switch (dataType) {
    case PERCENT: {
      return defaultNonNumbers(format(",~%"));
    }

    // case CURRENCY: {
    //   const formatter = R.compose(
    //     siToIllions,
    //     R.ifElse(isZeroToOne, format("$.2f"), format("$,.2~s")),
    //   );
    //   return defaultNonNumbers(formatter);
    // }

    case NUMBER: {
      const formatter = R.compose(siToIllions, format(",~s"));
      return R.compose(defaultNonNumbers, R.unless(isZeroToOne))(formatter);
    }

    // case RATE: {
    //   return defaultNonNumbers((d) => d);
    // }

    default:
      return (d) => d;
  }
}
